import { Root, Heading } from "mdast";
import createToc from "./createToC.js";

type PluginOptions = {
    addClassnameToFirstParagraph?: boolean;
    tocHeader?: string;
    injectAdBanner?: boolean;
};

const htmlCommentsRegex = /<!--([\s\S]*?)-->/g;

export default function MarkdownHandlingPlugin({
    addClassnameToFirstParagraph,
    tocHeader,
    injectAdBanner,
}: PluginOptions) {
    return (tree: Root) => {
        // add `introduction` classname to first paragraph for SEO puposes
        if (addClassnameToFirstParagraph) {
            const p = tree.children.find(i => i.type === "paragraph");
            if (p) {
                p.data = {
                    ...p.data,
                    hProperties: {
                        classname: "introduction",
                    },
                };
            }
        }

        // insert Google Ad block template if there are more than 12 items in article
        if (injectAdBanner && tree.children.length >= 12) {
            let pos = Math.floor(tree.children.length / 2);
            if (tree.children[pos].type === "heading") {
                do {
                    pos++;
                } while (tree.children[pos].type === "heading");
            }
            tree.children.splice(pos + 1, 0, {
                type: "inlineCode",
                value: "in-article-ad-block",
            });
        }

        // insert Custom Banner templates
        // TODO: logic of this process must be improved & unified
        for (let i = 0; i < tree.children.length; i++) {
            const node = tree.children[i];
            if (node.type === "html" && node.value.match(htmlCommentsRegex)) {
                // NOTE: next `if` statement added for parsing data for A/B testing texts
                if (node.value.includes("FEATURE_KEY") && i > 0) {
                    const [key, ...alts] = node.value
                        .split("\n")
                        .filter(r => r.match(/^(FEATURE_KEY|ALT_\d):.*/));
                    const prevNode = tree.children[i - 1];

                    prevNode.data = {
                        ...prevNode.data,
                        hProperties: {
                            ...(prevNode.data?.hProperties || {}),
                            featureKey: key.replace("FEATURE_KEY:", ""),
                            alternatives: alts.map(a =>
                                a.replace(/ALT_\d:/, ""),
                            ),
                        },
                    };
                } else if (node.value.includes("CUSTOM_AD_UNIT")) {
                    const match = node.value.match(
                        /CUSTOM_AD_UNIT:([a-z_\d]+)/,
                    );
                    if (match) {
                        tree.children.splice(i + 1, 0, {
                            type: "inlineCode",
                            value: `custom-ad-unit:${match[1]}`,
                        });
                    }
                } else if (node.value.includes("BANNER:")) {
                    const match = node.value.match(/BANNER:([^\s]+)\s*-->/);
                    if (match) {
                        tree.children.splice(i + 1, 0, {
                            type: "inlineCode",
                            value: `in-text-banner:${match[1]}`,
                        });
                    }
                }
                tree.children.splice(i, 1);
                i--;
            } else if (node.type === "blockquote") {
                if (
                    node.children.length === 1 &&
                    node.children[0].type === "paragraph"
                ) {
                    const p = node.children[0];
                    if (p.children.length === 1) {
                        const c = p.children[0];
                        if (c.type === "image") {
                            tree.children[i] = {
                                type: "inlineCode",
                                value: `image-with-caption:${c.url}`,
                            };
                        }
                    }
                }
            }
        }
        // building Table of Contents
        if (tocHeader) {
            const header: Heading = {
                type: "heading",
                depth: 2,
                children: [{ type: "text", value: tocHeader }],
            };

            const toc = createToc(tree);
            if (toc) {
                tree.children.splice(1, 0, header, toc, {
                    type: "inlineCode",
                    value: "in-article-ad-block-under-toc",
                });
            }
        }
    };
}
