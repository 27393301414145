import {
    calendarGrid,
    calendarHeaderCell,
    gridBackground,
} from "./CalendarGrid.css.js";
import CalendarCell from "./CalendarCell.js";
import { useContext, useEffect, useState } from "react";
import { PickedMonthContext } from "./contexts/DateContextProvider.js";
import { calendarCell } from "./CalendarCell.css.js";
import {
    WeekDay,
    getUserFirstWeekDay,
    getWeekDay,
    getWeekDays,
} from "../../helpers/utils.js";
import { Content } from "../layout/CommonContentContextProvider.js";

type CellConfig = {
    day: number;
    date: number;
} | null;

const fillMonthGrid = (
    firstDayOfMonth: number,
    firstWeekDay: WeekDay,
): CellConfig[] => {
    const d = new Date(firstDayOfMonth);
    const month = d.getUTCMonth();
    const config: CellConfig[] = [];

    const startOfWeekOffset = (getWeekDay(d) + 7 - firstWeekDay) % 7;

    for (let i = 0; i < startOfWeekOffset; i++) {
        config.push(null);
    }
    let lastWeekDay: number = 0;
    while (d.getUTCMonth() === month) {
        config.push({
            day: d.getUTCDate(),
            date: d.getTime(),
        });
        lastWeekDay = (getWeekDay(d) + 7 - firstWeekDay) % 7;
        d.setUTCDate(d.getUTCDate() + 1);
    }
    for (let i = lastWeekDay + 1; i < 7; i++) {
        config.push(null);
    }
    return config;
};

const CalendarGrid: React.FC = () => {
    const pickedMonth = useContext(PickedMonthContext);

    const [pickedFirstWeekDay, setFirstWeekDay] = useState<WeekDay>(1);
    useEffect(() => {
        const userFirstWeekDay = getUserFirstWeekDay();
        if (pickedFirstWeekDay !== userFirstWeekDay) {
            setFirstWeekDay(userFirstWeekDay);
        }
    }, []);
    const cells = fillMonthGrid(pickedMonth, pickedFirstWeekDay);
    return (
        <div className={gridBackground}>
            <div className={calendarGrid}>
                <WeekDays firstWeekDay={pickedFirstWeekDay} />
                {cells.map((c, i) =>
                    c === null ? (
                        <div key={"empty_cell_" + i} className={calendarCell} />
                    ) : (
                        <CalendarCell key={c.date} date={c.date} day={c.day} />
                    ),
                )}
            </div>
        </div>
    );
};

const WeekDays: React.FC<{ firstWeekDay: WeekDay }> = ({ firstWeekDay }) => {
    const { langCode } = useContext(Content);
    return (
        <>
            {getWeekDays(langCode, firstWeekDay).map(weekDayName => (
                <div key={weekDayName} className={calendarHeaderCell}>
                    {weekDayName}
                </div>
            ))}
        </>
    );
};

export default CalendarGrid;
