import gql from "graphql-tag";
import { LangCode } from "../../../_common/types.js";
import { QuizForBanner } from "./games.js";
import { InfographicsForBanner } from "./infographics.js";

export interface Copyright {
    author: string | null;
    url: string;
}

export interface EngagementInfo {
    facebook: number | null;
    messenger: number | null;
    twitter: number | null;
    telegram: number | null;
    whatsapp: number | null;
    native: number | null;
}

export interface Edge<T> {
    cursor: string;
    node: T;
}

export interface FeedConnection<T> {
    totalCount: number;
    offset: number;
    edges: Edge<T>[];
}
export interface QA {
    q: string;
    a: string;
}
export type TextBannerItem = InfographicsForBanner | QuizForBanner;

// TODO: should be converted to enum & merged with socialMediaMap from shared-redirector?
export const socialMediaTagsMap: { [key: string]: string } = {
    facebook: "fb",
    messenger: "fm",
    twitter: "tw",
    telegram: "tm",
    whatsapp: "wa",
    native: "na",
};
export interface SubscribeToNewsletterPayload {
    message: string;
}
export interface SubscribeToNewsletterInput {
    email: string;
    firstName?: string | null;
    lastName?: string | null;
    langCode: LangCode;
    tzOffset: number;
}
export const SUBSCRIBE_TO_NEWS_LETTER = gql`
    mutation subscribeToNewsletter($input: SubscribeToNewsletterInput!) {
        result: subscribeToNewsletter(input: $input) {
            message
        }
    }
`;
