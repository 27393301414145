import React, { useContext } from "react";
import { TextBannerItem } from "../../../graphql/queries/_common.js";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { QuizForBanner } from "../../../graphql/queries/games.js";
import { gaMarkdownLinkClick } from "../../../helpers/googleAnalytics.js";
import MediaItem from "../../gallery/_common/MediaItem.js";
import { Content } from "../../layout/CommonContentContextProvider.js";
import Icon from "../../svg/Icon.js";
import {
    bannerContent,
    imageContainer,
    bannerText,
    banner,
    icon,
    button,
} from "./InTextBanner.css.js";
import { InfographicsForBanner } from "../../../graphql/queries/infographics.js";

interface Props {
    item: TextBannerItem;
}

const InTextBanner: React.FC<Props> = ({ item }) => {
    if (item.__typename === "Quiz") {
        return <QuizBanner item={item} />;
    }
    if (item.__typename === "Infographics") {
        return <InfographicsBanner item={item} />;
    }
    return null;
};

interface QuizBannerProps {
    item: QuizForBanner;
}
const QuizBanner: React.FC<QuizBannerProps> = ({ item }) => {
    const {
        id,
        content: { metaDescription, startButton, mediaItem },
    } = item;
    const { langCode } = useContext(Content);
    const { pathname } = useLocation();

    const to = `/quiz/${id}#0`;
    return (
        <div className={banner}>
            <Icon className={icon} k="handPoint" />
            <div className={bannerContent}>
                <div className={imageContainer}>
                    <MediaItem
                        mediaItem={mediaItem}
                        srcSetSizes={{
                            largePhone: 180,
                        }}
                    />
                </div>
                <div className={bannerText}>{metaDescription}</div>
            </div>
            <Link
                className={button}
                to={`/${langCode}${to}`}
                onClick={() =>
                    gaMarkdownLinkClick(
                        "internal_banner",
                        to,
                        langCode,
                        pathname,
                    )
                }
            >
                {startButton}
            </Link>
        </div>
    );
};

interface InfographicsBannerProps {
    item: InfographicsForBanner;
}

const InfographicsBanner: React.FC<InfographicsBannerProps> = ({ item }) => {
    const {
        id,
        content: { preview },
        previewImage,
    } = item;
    const {
        langCode,
        newsPage: { infographicsBannerButton },
    } = useContext(Content);
    const { pathname } = useLocation();

    const to = `/infographics/${id}`;

    return (
        <div className={banner}>
            <Icon className={icon} k="handPoint" />
            <div className={bannerContent}>
                <div className={imageContainer}>
                    <MediaItem
                        mediaItem={previewImage}
                        srcSetSizes={{
                            largePhone: 180,
                        }}
                    />
                </div>
                <div className={bannerText}>{preview}</div>
            </div>
            <Link
                className={button}
                to={`/${langCode}${to}`}
                onClick={() =>
                    gaMarkdownLinkClick(
                        "internal_banner",
                        to,
                        langCode,
                        pathname,
                    )
                }
            >
                {infographicsBannerButton}
            </Link>
        </div>
    );
};

export default InTextBanner;
